<script lang="ts">
const image_width = 1184
</script>
<template>
  <div
    v-if="product.name"
    class="bg-white overflow-hidden"
  >
    <cgn-breadcrumb v-for="(breadcrumb, index) in breadcrumbs" :key="index" :links="breadcrumb" />
    <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-8">
      <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
        <div>
          <h1
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl"
          >
            {{ product.name }}
          </h1>
        </div>
      </div>
      <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
        <div class="relative lg:row-start-1 lg:col-start-2">
          <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
            <figure>
              <div>
                <div
                  v-for="(image, index) in product.images"
                  :key="index"
                  class="aspect-w-12 aspect-h-7 lg:aspect-none rounded-lg bg-white"
                >
                  <cgn-lazy-image
                    v-if="image_index == index"
                    class="rounded-lg object-cover object-center border border-gray-400"
                    :image="image"
                    :width="image_width"
                  />
                </div>
              </div>
              <div v-if="product.images.length > 1" class="grid grid-cols-4 gap-4 mt-2">
                <div
                  v-for="(image, index) in product.images"
                  :key="index"
                  class="cursor-pointer rounded-lg transition-colors duration-300 overflow-hidden bg-black dark:bg-white border hover:border-brand-500 border-gray-400"
                  @click="swapImage(index)"
                >
                  <cgn-lazy-image
                    class="rounded-lg object-cover object-center hover:opacity-80 transition-opacity duration-300"
                    :image="image"
                  />
                </div>
              </div>
            </figure>
          </div>
        </div>
        <div class="mt-8 lg:mt-0">
          <div
            class="mt-5 prose prose-brand dark:prose-dark text-gray-500 dark:text-gray-200 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1"
            v-html="product.content"
          />
          <product-cart-options :product="product" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SellProduct } from '~cognito/models/Sell/Product'
import { CognitoGroup } from '~cognito/models/Cognito/Group'

const props = defineProps({
  product: {
    type: String,
    required: true,
  },
})

const product = ref<SellProduct>(new SellProduct())

const image_index = ref(0)



const breadcrumbs = ref([{ name: 'Shop', url: '/shop' }])

function generateBreadcrumbs() {
  breadcrumbs.value = []
  product.value.groups?.forEach(async (group) => {
    const breadcrumb = ref([{ name: 'Shop', url: '/shop' }])

    const groupDetails = await new CognitoGroup().findByUrl('Sell\\Product', group.slug)

    const parentGroups = await groupDetails.getParents()
    parentGroups.forEach((parentGroup) => {
      if (parentGroup.isRoot()) {
        return
      }
      breadcrumb.value.push({ name: parentGroup.name, url: `/shop/${parentGroup.url}` })
    })

    breadcrumb.value.push({ name: groupDetails.name, url: `/shop/${groupDetails.url}` })
    breadcrumb.value.push({ name: product.value.name, url: product.value.slug })

    breadcrumbs.value.push(breadcrumb.value)
  })
}

async function loadProduct(url: string) {
  product.value.name = ''
  const data = await new SellProduct().find_one({
    url,
    image_aspect: 'Square',
    image_width,
  })
  product.value = data
  generateBreadcrumbs()
  image_index.value = 0
}

useSchemaOrg([
  defineProduct({
    // name and description can usually be inferred
    name: computed(() => product.value?.name),
    description: computed(() => product.value?.blurb),
    image: computed(() => product.value?.image?.url),
    brand: {
      name: computed(() => product.value?.brand ? product.value.brand : 'Generic'),
    },
    offers: [
      defineOffer({
        priceCurrency: 'AUD',
        price: computed(() => product.value?.price.toFixed(2)),
        availability: computed(() => product.value?.in_stock ? 'InStock' : 'OutOfStock'),
      }),
    ],
  }),
])

function swapImage(index: number) {
  image_index.value = index
}

watch(() => props.product, (newUrl) => {
  loadProduct(newUrl)
})
onMounted(() => {
  loadProduct(props.product)
})
onServerPrefetch(async () => {
  await loadProduct(props.product)
})
</script>
