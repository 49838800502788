<template>
  <div v-if="widget.outer == 'tpl/heading'">
    <page-builder-heading :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/background_video'">
    <page-builder-background-video :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/4_grid_gallery'">
    <page-builder-tpl-4-grid-gallery :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/headline_event'">
    <page-builder-tpl-headline-event :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/upcoming_events'">
    <page-builder-tpl-upcoming-events :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Tpl/contact_form'">
    <page-builder-contact :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Event/venue_list'">
    <page-builder-event-venue-list :templatevar="widget.templatevar" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_grid'">
    <page-builder-tpl-shop :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Sell/product_view'">
    <page-builder-tpl-sell-product-view :templatevar="widget.templatevar" :product="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/upcoming_list'">
    <page-builder-event-upcoming-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/3_small_upcoming_list'">
    <page-builder-event-3-small-upcoming-list :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/hirer_upcoming_events'">
    <page-builder-hirer-upcoming-events :templatevar="widget.templatevar" :group="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/view'">
    <page-builder-event-view :templatevar="widget.templatevar" :event="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/calendar'">
    <cgn-page-builder-event-calendar :templatevar="widget.templatevar" :event="props.urlParts.item_url" :page="props.urlParts.url_parameter_2" />
  </div>
  <div v-else-if="widget.outer == 'Event/series'">
    <page-builder-event-series-group :templatevar="widget.templatevar" :group="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/series_entry'">
    <page-builder-event-series :templatevar="widget.templatevar" :series="props.urlParts.item_url" />
  </div>
  <div v-else-if="widget.outer == 'Event/merch_list'">
    <page-builder-event-merch-list :templatevar="widget.templatevar" />
  </div>
  <cgn-page-builder v-else :widget="widget" :url-parts="urlParts" :contained-class="props.containedClass" />
</template>

<script setup lang="ts">
import { CognitoUrlParts } from '~cognito/models/Cognito/Page'

const props = defineProps({
  widget: {
    required: true,
  },
  containedClass: {
    type: String,
    default: 'px-6 py-2 mx-auto max-w-5xl',
  },
  urlParts: {
    type: CognitoUrlParts,
    required: true,
  },
})
</script>
