<template>
  <div v-if="seriesDetails.cognitoGroup" class="mx-auto my-2 w-full max-w-2xl px-4">
    <div class="pb-2 text-2xl font-semibold">
      {{ seriesDetails.cognitoGroup.name }}
    </div>
    <div class="prose max-w-none pb-6 prose-p:my-2" v-html="seriesDetails.cognitoGroup.content" />
    <div v-for="series in seriesDetails.eventSeriess" :key="series.url" class="pb-6">
      <div class="text-xl font-semibold text-brand-500">
        {{ series.name }}
      </div>
      <div class="prose max-w-none prose-p:my-2" v-html="series.content" />
      <ul class="list-disc pb-2 pl-4">
        <li v-for="category in series.seriesCategories" :key="category.name">
          {{ category.code }}
          {{ category.name }}
        </li>
      </ul>
      <cgn-button color-brand :url="`/enter-series/${series.url}`" class="font-semibold">
        Enter Series
      </cgn-button>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { $axios } from '~cognito/plugins/axios'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  group: {
    type: String,
    default: 'page',
  },
  page: {
    type: Number,
    default: 1,
  },
})

const seriesDetails = ref({})

const load = async () => {
  let group = props.group
  if (group === 'page') {
    group = ''
  }

  seriesDetails.value = await $axios.graphql(gql`query seriesGroupQuery($groupUrl: String!) {
  cognitoGroup(url: $groupUrl, model: "Event\\\\Series") {
    id
    name
    content
  }
  eventSeriess(byGroup: $groupUrl) {
    url
    name
    content
    seriesCategories {
      code
      name
    }
  }
}`, {
    groupUrl: props.group,
  })
}

onMounted(() => {
  load()
})
onServerPrefetch(async () => {
  await load()
})
</script>
