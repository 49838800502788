<template>
  <div v-if="events.length > 0" class="bg-darkbg-700">
    <div class="pt-2 text-center text-2xl font-semibold text-white">
      {{ hirer_name }}
    </div>
    <div class="pt-2 text-center text-2xl font-semibold text-white">
      Upcoming Events
    </div>
    <div class="mx-auto grid max-w-sm grid-cols-1 gap-8 p-4 text-white sm:max-w-6xl sm:grid-cols-3">
      <cgn-link
        v-for="event in events" :key="event.id" :to="buttonLink(event)"
        class="flex flex-col overflow-hidden" :class="{ 'pointer-events-none': !buttonLink(event) }"
      >
        <div class="aspect-[4/3] overflow-hidden rounded">
          <cgn-lazy-image :image="event.type.photo" class="w-full" />
        </div>
        <div class="flex flex-1 flex-col justify-between text-center">
          <div class="text-2xl font-semibold">
            {{ event.name }}
          </div>
          <div class="flex justify-evenly text-lg">
            <div>
              {{ event.start_date.format('do MMMM Y') }}
            </div>
            <div class="pb-2 text-lg font-semibold text-brand-500">
              {{ event.type.name }}
            </div>
          </div>
        </div>
        <cgn-button v-if="buttonLink(event)" color-brand>
          <template v-if="event.can_enter">
            Enter
          </template>
          <template v-else>
            View More
          </template>
        </cgn-button>
      </cgn-link>
    </div>
  </div>
  <cgn-alert-warning v-else class="mx-auto max-w-2xl">
    No events found
  </cgn-alert-warning>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { $axios } from '~cognito/plugins/axios'
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  group: {
    type: String,
  },
  page: {
    type: String,
  },
})
const events = ref<EventEvent[]>([])
const hirer_name = ref('')

const buttonLink = (event) => {
  if (event.can_enter) {
    return `/event/${event.url}`
  }
  if (event.entry_form_link) {
    return event.entry_form_link
  }
  if (event.hirer_entry_form_link) {
    return event.hirer_entry_form_link
  }
  return ''
}

async function loadEvents() {
  const data = await $axios.graphql(gql`
  query($hirer: String) {
  eventEvents(first: 3, isFuture: true, hirer_url: $hirer) {
    id
    name
    url
    can_enter
    entry_form_link
    hirer_entry_form_link
    hirer_company_name
    type {
      name
      photo(image_aspect: "4x3", image_width: 600) {
        url
        width
        height
      }
    }
    start_date
  }
  }
  `, {
    hirer: props.group,
  })

  events.value = new EventEvent().map(data.eventEvents)
  events.value.forEach((event) => {
    hirer_name.value = event.hirer_company_name
  })
}
onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
</script>
