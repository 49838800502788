import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://morganpark.${config.devbase}`
config.prodURL = 'https://cms.morganparkraceway.com.au'
config.siteURL = 'https://www.morganparkraceway.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.payment_to_user_id = 196
config.profile.edit_date_of_birth = true
config.profile.edit_drivers_licence = true
config.profile.edit_emergency_contact = true
config.sentryDsn = 'https://72eb58dd82474161b6ad435fe69a0a6c@glitchtip.logger.jm1.au/17'

export { config }
