<template>
  <div v-if="has_payment" class="mx-auto my-2 w-full max-w-2xl">
    <cgn-alert-success v-if="payment_ok">
      Payment Successful
      Please check your email for your receipt
    </cgn-alert-success>
    <cgn-alert-danger v-else>
      Payment Failed
    </cgn-alert-danger>
  </div>
  <cgn-spinner v-else-if="loading" />
  <div v-else-if="calculatedPrice">
    <div class="relative h-[300px] sm:h-[500px]">
      <div class="absolute inset-0 bg-cover bg-center" :style="{ 'background-image': `url(${eventDetails.type.photo?.url})` }" />
    </div>
    <form class="mx-auto max-w-6xl p-2" @submit.prevent="submitEntry">
      <div class="text-center text-4xl font-bold">
        {{ eventDetails.name }}
      </div>
      <div class="text-center text-2xl font-semibold">
        {{ eventDetails.venue.name }}
      </div>
      <div class="text-center text-2xl font-semibold">
        {{ eventDetails.start_date.toHumanDateString(true) }}
      </div>
      <div v-if="eventDetails.start_date.hour()" class="text-center text-2xl font-semibold">
        {{ eventDetails.start_date.toHumanTimeString() }}
      </div>
      <div v-if="eventDetails.can_enter && entryForm.entryCategoryRadio.length > 1 && eventDetails.user_cannot_enter_reason.length == 0" class="font-bold">
        <div class="pt-4 text-center text-2xl">
          Enter
        </div>
        <div class="prose max-w-none" v-html="eventDetails.entrant_content" />
        <cgn-form-radio-button v-model="entryForm.category_id" :options="entryForm.entryCategoryRadio" />

        <div v-if="selectedCategory">
          <div v-if="selectedCategory.is_entry_transfer">
            <cgn-form-input v-model="entryForm.entry_transfer_from_name" label="Name of Person your are transferring ticket from" required />
            <cgn-form-input v-model="entryForm.entry_transfer_from_invoice" type="number" label="Invoice Number of the ticket being transferred" required />
          </div>
          <cgn-form-address-selector
            v-model="entryForm.address_id"
            v-model:addresses="addresses"
            here-api-key="GOBLSSIkkrgjhMahFmXPramj-95rVXZYpj-0pj7DsFU"
          />

          <template v-if="selectedCategory?.requires_vehicle">
            <cgn-form-dropdown
              v-model="entryForm.vehicle_id"
              label="Vehicle"
              prompt="New Vehicle"
              :options="entryForm.vehicles.map(e => { return { id: e.id, name: `${e.make} ${e.model} ${e.colour} ${e.registration}` } })"
              class="w-full"
              required
            />
            <cgn-vehicle-edit v-if="!entryForm.vehicle_id || (!selectedVehicle?.photo?.url && eventDetails.require_vehicle_photo) || (selectedVehicle?.registrationHasExpired() && selectedCategory?.vehicle_must_be_registered)" v-model="entryForm.vehicle_id" v-model:vehicles="entryForm.vehicles" no-scroll :require-photo="eventDetails.require_vehicle_photo" :require-registration="selectedCategory?.vehicle_must_be_registered ?? true" />
            <div v-if="!selectedVehicle?.photo?.url && eventDetails.require_vehicle_photo">
              Vehicle Photo is required
              <input type="readonly" class="h-0 w-0" required>
            </div>
            <div v-if="selectedVehicle?.registrationHasExpired() && selectedCategory?.vehicle_must_be_registered">
              <cgn-alert-danger>
                The vehicle registration has expired. Please update it.
                <input type="readonly" class="h-0 w-0" required>
              </cgn-alert-danger>
            </div>
            <div v-if="selectedCategory?.ask_if_sharing_vehicle">
              <cgn-form-input v-model="entryForm.sharing_vehicle_with" label="I am sharing a vehicle with" />
            </div>
          </template>
          <div v-if="selectedCategory?.ask_expected_time">
            <cgn-form-input v-model="entryForm.expected_time" type="number" label="My expected lap time is (in seconds)" required />
          </div>
          <div v-if="eventDetails.race_licence_required && !selectedCategory?.no_race_licence_required">
            <div class="pt-4 text-center text-2xl">
              Race Licence Required
              <div class="text-xl">
                {{ eventDetails.race_licence_required.name }}
              </div>
            </div>
            <cgn-form-input v-model="entryForm.race_licence_number" label="Race Licence Number" required />
            <cgn-form-input v-model="entryForm.race_licence_expiry" type="date" label="Race Licence Expiry" required />
          </div>
          <div v-if="entryForm.extras">
            <div class="pt-4 text-center text-2xl">
              Add to your entry
            </div>
            <div v-for="extra in entryForm.extras" :key="extra.id">
              <label class="block cursor-pointer rounded-lg border-2 border-gray-300 bg-white px-6 py-4 shadow-sm dark:border-gray-700 dark:bg-slate-600 sm:flex sm:justify-between">
                <div class="cgn-radio-button text-sm">
                  <div class="flex items-center">
                    <input v-if="!extra.disabled" v-model="extra.checked" type="checkbox" class="mr-2">
                    <div>
                      {{ extra.label }}
                      ${{ extra.price.toFixed(2) }}
                    </div>
                  </div>
                  <div v-if="extra.disabled" class="cgn-radio-button-content text-xs">
                    Sorry Sold out
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <cgn-alert-danger v-else>
        You cannot enter this event
        <span v-if="eventDetails.entries_open_at.isFuture()">
          -
          Entries open
          {{ eventDetails.entries_open_at.toHumanDateString(false) }}
          {{ eventDetails.entries_open_at.toHumanTimeString() }}
        </span>
        <span v-else-if="eventDetails.user_cannot_enter_reason">
          -
          {{ eventDetails.user_cannot_enter_reason }}
          <cgn-button color-secondary url="/profile">
            Update my Profile
          </cgn-button>
        </span>
      </cgn-alert-danger>

      <div v-if="entryForm.category_id > -1">
        <template v-if="false">
          <div class="pt-4 text-center text-2xl font-bold">
            Licence
          </div>
          <cgn-form-radio-button v-model="entryForm.licence_id" :options="entryForm.licenceTypeRadio" />
          <cgn-form-input v-if="entryForm.licence_id == 0 && entryForm.category_id != 0" v-model="entryForm.aasa_licence" label="AASA Licence" required />
          <div v-if="selectedLicence?.is_annual">
            <div class="pt-4 text-center text-2xl">
              Medical Statement
            </div>
            <cgn-form-dropdown v-model="entryForm.blood_type_id" :options="selectedLicence.blood_types" label="Blood Type" required />
            <cgn-form-input v-model="entryForm.date_of_last_tetanus_vaccination" type="date" label="Tetanus immunisation date" required />
            <cgn-form-tristate v-model="entryForm.has_allergies" label="Allergies" required />
            <cgn-form-tristate v-model="entryForm.has_had_psychiatric_psychological_illness" label="Have You ever been diagnosed as having/or had treatment for a Psychiatric or Psychological illness" required />
            <cgn-form-tristate v-model="entryForm.has_had_brain_injury" label="Persistent or severe headache, head injury, epilepsy, seizure or loss of consciousness?" required />
            <cgn-form-tristate v-model="entryForm.has_had_heart_issues" label="Heart or lung disease, including infection, blood vessel disease, hypertension, coronary bypass, angioplasty or other surgical procedure?" required />
            <cgn-form-tristate v-model="entryForm.has_had_cancer_diabeties" label="Cancer, diabetes, kidney, liver, thyroid, gastrointestinal, blood pressure disorders, including any associated surgical procedures?" required />
            <cgn-form-tristate v-model="entryForm.has_had_significant_illness_or_injury" label="Any other significant illness, injury or surgery not already noted?" required />
            <cgn-form-tristate v-model="entryForm.is_on_medication" label="Have you taken any medications, including self-medication or alternative therapies?" required />
            <cgn-form-tristate v-model="entryForm.has_hearing_impairment" label="Do you have any hearing impairment or loss?" required />
            <cgn-form-tristate v-model="entryForm.has_hearing_disorder" label="Do you suffer from any hearing disorder including tinnitus?" required />
            <cgn-form-tristate v-model="entryForm.is_normal_eyesight" label="Is your eyesight normal in both eyes for distance vision?" required />
            <cgn-form-input v-model="entryForm.date_of_last_medical_exam" type="date" label="When did you last have a medical examination?" required />
            <div v-if="selectedLicence.terms_and_conditions">
              <div class="prose max-w-none text-sm" v-html="selectedLicence.terms_and_conditions.content" />
              <cgn-form-checkbox label="I have read and accept the Terms & Conditions" required />
            </div>
          </div>
        </template>
      </div>

      <template v-if="eventDetails.garage_hire && eventDetails.garages.length > 0">
        <div class="pt-4 text-center text-2xl font-bold">
          Garage Hire
        </div>
        <cgn-form-radio-button
          v-if="!selectedCategory?.requires_garage" v-model="want_a_garage" :options="[
            {
              name: 'I do not need a Garage / Carport',
              id: 0,
            },
            {
              name: 'I want to hire a Garage / Carport',
              id: 1,
            },
          ]"
        />
        <div v-if="showGaragePicker" class="my-2">
          <cgn-map-site-picker
            v-model="entryForm.garage_id"
            :image="eventDetails.garage_map"
            :sites="garageTypeMap"
            selected-colour="#5555ff"
            unselected-colour="#aaffaa"
            unavailable-colour="#ffaaaa"
          />
        </div>
      </template>
      <template v-if="eventDetails.offer_camp_sites && eventDetails.camp_sites.length > 0">
        <div class="pt-4 text-center text-2xl font-bold">
          Camping
        </div>
        <cgn-form-radio-button
          v-model="want_to_camp" :options="[
            {
              name: 'I do not need a Camp Site',
              id: 0,
            },
            {
              name: 'I want to Camp',
              id: 1,
            },
          ]"
        />
        <div v-if="want_to_camp" class="my-2">
          <cgn-map-site-picker
            v-model="entryForm.camp_site_ids"
            :image="eventDetails.camp_site_map"
            :sites="campSiteTypeMap"
            selected-colour="#5555ff"
            unselected-colour="#aaffaa"
            unavailable-colour="#ffaaaa"
            :choose-multiple="true"
          />
        </div>
      </template>

      <div v-if="entryForm.spectators.length && eventDetails.can_buy_spectator_ticket" class="font-bold">
        <div class="pt-4 text-center text-2xl">
          Spectate
        </div>
        <div class="prose" v-html="eventDetails.spectator_content" />
        <div class="divide-y overflow-hidden rounded-lg border">
          <div v-for="(spectator, index) in entryForm.spectators" :key="spectator.id" :class="index % 2 ? 'bg-gray-100' : 'bg-gray-50'" class="px-2 py-1">
            <cgn-form-input v-if="!spectator.disabled" v-model="spectator.qty" type="number" min-amount="0" :max-amount="spectator.qty_available > 0 ? spectator.qty_available : 100" :label="`${spectator.label} $${spectator.price.toFixed(2)}`" />
            <div v-else class="py-4 text-lg">
              {{ spectator.label }} - Sold out
            </div>
          </div>
        </div>
        <cgn-form-address-selector
          v-model="entryForm.address_id"
          v-model:addresses="addresses"
          here-api-key="GOBLSSIkkrgjhMahFmXPramj-95rVXZYpj-0pj7DsFU"
        />
      </div>

      <div v-if="eventDetails.products.length" class="font-bold">
        <div class="pt-4 text-center text-2xl">
          Merch
        </div>
        <div class="prose" v-html="eventDetails.merch_content" />
        <div class="space-y-2">
          <div v-for="product in eventDetails.products" :key="product.id" class="overflow-hidden rounded-md border">
            <div class="flex flex-col sm:flex-row">
              <div class="sm:w-1/4">
                <cgn-lazy-image :image="product.photos[0]" class="w-full" />
              </div>
              <div class="flex flex-col p-2 sm:w-3/4">
                <div class="text-3xl">
                  {{ product.name }}
                </div>
                <div v-if="product.selectedOption" class="text-2xl">
                  ${{ product.options.find(e => e.id == product.selectedOption)?.price.toFixed(2) }}
                </div>
                <div v-if="product.description" class="pt-2">
                  {{ product.description }}
                </div>
                <div v-if="product.options.length > 0">
                  <cgn-form-dropdown v-model="product.selectedOption" label="Option" :options="product.options" />
                </div>
                <div class="flex h-full items-end">
                  <cgn-button v-if="product.selectedOption" color-brand is-label fullwidth @click="addProductToOrder(product)">
                    Add to order
                  </cgn-button>
                  <cgn-button v-else color-brand is-label fullwidth disabled>
                    Select an option
                  </cgn-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="eventDetails.merch.length" class="font-bold">
        <div class="pt-4 text-center text-2xl">
          Merch
        </div>
        <div class="space-y-2">
          <div v-for="merch in eventDetails.merch" :key="merch.id" class="overflow-hidden rounded-md border">
            <div class="flex flex-col sm:flex-row">
              <div class="sm:w-1/4">
                <cgn-lazy-image :image="merch.photos[0]" class="w-full" />
              </div>
              <div class="flex flex-col p-2 sm:w-3/4">
                <div class="text-3xl">
                  {{ merch.name }}
                </div>
                <div class="text-2xl">
                  ${{ merch.price_each.toFixed(2) }}
                </div>
                <div v-if="merch.description" class="pt-2">
                  {{ merch.description }}
                </div>
                <div v-if="merch.options.length > 0">
                  <cgn-form-dropdown v-model="merch.selectedOption" label="Option" :options="merch.options" />
                </div>
                <div class="flex h-full items-end">
                  <cgn-button v-if="merch.options.length == 0 || merch.selectedOption" color-brand is-label fullwidth @click="addMerchToOrder(merch)">
                    Add to order
                  </cgn-button>
                  <cgn-button v-else color-brand is-label fullwidth disabled>
                    Select an option
                  </cgn-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedCategory" class="my-2 rounded-md border p-2">
        <div class="h-[300px] overflow-scroll">
          <div class="prose max-w-none pb-4" v-html="eventDetails.type.terms_and_conditions.content" />
        </div>
        <cgn-form-checkbox label="I have read and accept the Terms & Conditions" class="font-bold" required />
      </div>
      <div class="py-2 font-bold">
        <div v-if="selectedCategory" class="my-2 rounded-md border p-2 text-sm">
          <div class="text-xl">
            {{ selectedCategory.name }} ${{ selectedCategory.price.toFixed(2) }}
          </div>
          <div v-if="selectedAddress">
            Address: {{ selectedAddress.street_address }}
          </div>
          <div v-if="selectedVehicle">
            Vehicle: {{ selectedVehicle.make }} {{ selectedVehicle.model }}
          </div>
          <div v-if="selectedCategory.ticket_type && selectedCategory.spectator_tickets_per_entry > 0">
            <div>
              1 x Driver entry ( {{ selectedCategory.ticket_type }} )
            </div>
            <div v-if="selectedCategory.spectator_tickets_per_entry > 1">
              {{ selectedCategory.spectator_tickets_per_entry - 1 }} x Crew entry ( {{ selectedCategory.ticket_type }} )
            </div>
          </div>
        </div>
        <div v-for="extra in entryForm.extras.filter(e => e.checked == true)" :key="extra.id" class="my-2 flex items-center justify-between rounded-md border px-2 py-1 text-lg">
          {{ extra.label }} ${{ extra.price.toFixed(2) }}
        </div>
        <div v-if="entryForm.licence_id" class="my-2 flex items-center justify-between rounded-md border px-2 py-1 text-lg">
          {{ selectedLicence.name }} ${{ selectedLicence.price.toFixed(2) }}
        </div>
        <div v-for="spectator in entryForm.spectators.filter(e => e.qty > 0)" :key="spectator.id" class="my-2 flex items-center gap-2 rounded-md border px-2 py-1">
          <div class="min-w-max text-xs">
            <div class="text-lg">
              {{ spectator.label }} ${{ (spectator.price * spectator.qty).toFixed(2) }}
            </div>
            <div>
              Spectator ticket
            </div>
            <div>
              ${{ spectator.price.toFixed(2) }} each
            </div>
          </div>
          <cgn-form-input v-model="spectator.qty" class="w-full" type="number" min-amount="0" :max-amount="spectator.qty_available > 0 ? spectator.qty_available : 100" />
        </div>
        <div v-for="(merch, index) in entryForm.merch" :key="merch.id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
          <span>{{ merch.name }} ${{ merch.price.toFixed(2) }}</span>
          <div class="cursor-pointer p-2 text-gray-500" @click="entryForm.merch.splice(index, 1)">
            <i-heroicons-solid:x />
          </div>
        </div>
        <div v-for="(sku, index) in entryForm.skus" :key="sku.id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
          <span>{{ sku.name }} ${{ sku.price.toFixed(2) }}</span>
          <div class="cursor-pointer p-2 text-gray-500" @click="entryForm.skus.splice(index, 1)">
            <i-heroicons-solid:x />
          </div>
        </div>
        <div v-if="entryForm.garage_id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
          {{ garageTypeRadio.find(e => e.id == entryForm.garage_id)?.name }}
        </div>
        <div v-if="entryForm.camp_site_id" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
          {{ campSiteTypeRadio.find(e => e.id == entryForm.camp_site_id)?.name }}
        </div>
        <div v-for="site_id, idx in entryForm.camp_site_ids" :key="idx" class="my-2 flex items-center justify-between gap-2 rounded-md border px-2 py-1 text-lg">
          {{ campSiteTypeRadio.find(e => e.id == site_id)?.name }}
        </div>
        <div v-if="calculatedPrice.total > 0" class="my-2 rounded-md border p-2 text-2xl">
          Total ${{ calculatedPrice.total.toFixed(2) }}
        </div>
        <div v-if="eventDetails.credit_balance > 0" class="my-2 rounded-md border p-2">
          Credit balance: $ {{ eventDetails.credit_balance.toFixed(2) }}
        </div>
        <div v-if="calculatedPrice.payable > 0" class="pt-4 text-center text-2xl font-bold">
          Pay by
        </div>
        <cgn-form-radio-button v-if="calculatedPrice.payable" v-model="entryForm.payment_gateway" :options="eventDetails.payment_methods" />
        <div v-if="surcharge" class="my-2 rounded-md border p-2">
          Admin Processing Fee ${{ surcharge.toFixed(2) }}
        </div>
      </div>
      <cgn-alert-danger v-if="errorMessage" class="font-bold">
        {{ errorMessage }}
      </cgn-alert-danger>
      <cgn-alert-danger v-if="!garageHireOk" class="font-bold">
        Please choose a Garage / Carport to hire
      </cgn-alert-danger>
      <cgn-button v-if="(calculatedPrice.total > 0 || selectedCategory?.id) && entryForm.payment_gateway && garageHireOk" color-brand fullwidth class="font-bold">
        <template v-if="calculatedPrice.payable + surcharge > 0">
          Pay ${{ (calculatedPrice.payable + surcharge).toFixed(2) }} Now
        </template>
        <template v-else>
          Complete Order
        </template>
      </cgn-button>
    </form>
  </div>
</template>

<script lang="ts">
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import type { CognitoAddress } from '~cognito/models/Cognito/Address'
import { EventEvent } from '~cognito/models/Event/Event'
import type { EventMerch } from '~cognito/models/Event/Merch'
import type { EventProduct } from '~cognito/models/Event/Product'
import { requireLoggedIn } from '~cognito/plugins/axios'
import { useToastStore } from '~cognito/stores/toast'
import { EventEntryForm } from '~cognito/models/Event/EntryForm'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  event: {
    type: String,
    required: true,
  },
  page: {
    type: String,
  },
})

const entryForm = ref<EventEntryForm>(new EventEntryForm())

const selectedCategory = computed(() => {
  return entryForm.value.selectedCategory()
})
const selectedLicence = computed(() => {
  return entryForm.value.selectedLicence()
})

const loading = ref(false)
const eventDetails = ref<EventEvent>(new EventEvent())

const errorMessage = ref('')
const has_payment = ref(false)
const want_a_garage = ref(0)
const want_to_camp = ref(0)
const payment_ok = ref(false)
const submitEntry = async () => {
  errorMessage.value = ''
  const result = await new EventEvent().submitEntry(entryForm.value)
  errorMessage.value = result.message
  if (result.success) {
    window.location = result.redirect
  }
}

const showGaragePicker = computed(() => {
  return want_a_garage.value || selectedCategory.value?.requires_garage
})

const garageHireOk = computed(() => {
  if (!showGaragePicker.value) {
    return true
  }
  return !!entryForm.value.garage_id
})

const addMerchToOrder = (merch: EventMerch) => {
  entryForm.value.addMerchToOrder(merch)
  useToastStore().addToast(`${merch.name} added to order`, 'success', 2500)
}

const addProductToOrder = (product: EventProduct) => {
  entryForm.value.addProductToOrder(product)
  useToastStore().addToast(`${product.name} added to order`, 'success', 2500)
}

const calculatedPrice = computedAsync(async () => {
  return await entryForm.value.calculatedPrice()
})

const surcharge = computed(() => {
  return calculatedPrice.value.surcharges.find(e => e.type == entryForm.value.payment_gateway)?.surcharge ?? 0
})

const addresses = ref<CognitoAddress[]>([])
const selectedAddress = computed(() => {
  return entryForm.value.selectedAddress(addresses.value)
})

const selectedVehicle = computed(() => {
  return entryForm.value.selectedVehicle()
})

const garageTypeRadio = computed(() => {
  entryForm.value.calculateGarageTypeRadio({
    show_booked_by: false,
    price_included_in_entry: selectedCategory.value?.garage_included_in_entry_fee ?? false,
  })
  return entryForm.value.garageTypeRadio
})

const garageTypeMap = computed(() => {
  entryForm.value.calculateGarageTypeMap({
    show_booked_by: true,
    price_included_in_entry: selectedCategory.value?.garage_included_in_entry_fee ?? false,
  })
  return entryForm.value.garageTypeMap
})

const campSiteTypeRadio = computed(() => {
  entryForm.value.calculateCampSiteTypeRadio({
    show_booked_by: false,
    price_included_in_entry: false,
  })
  return entryForm.value.campSiteTypeRadio
})

const campSiteTypeMap = computed(() => {
  entryForm.value.calculateCampSiteTypeMap({
    show_booked_by: false,
    price_included_in_entry: false,
  })
  return entryForm.value.campSiteTypeMap
})

onMounted(async () => {
  requireLoggedIn()
  loading.value = true
  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
    })

  eventDetails.value = await entryForm.value.loadEvent(props.event)
  entryForm.value.category_id = -1

  loading.value = false
})
</script>
