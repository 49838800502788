<template>
  <div class="bg-darkbg-700">
    <div class="pt-2 text-center text-2xl font-semibold text-white">
      Upcoming Events
    </div>
    <div class="mx-auto grid max-w-sm grid-cols-1 gap-8 p-4 text-white sm:max-w-6xl sm:grid-cols-3">
      <cgn-link
        v-for="event in events" :key="event.id" :to="buttonLink(event)"
        class="flex flex-col overflow-hidden" :class="{ 'pointer-events-none': !buttonLink(event) }"
      >
        <div class="aspect-[4/3] overflow-hidden rounded">
          <cgn-lazy-image v-if="event.image.url" :image="event.image" class="w-full" />
          <cgn-lazy-image v-else :image="event.type.photo" class="w-full" />
        </div>
        <div class="flex flex-1 flex-col justify-between text-center">
          <div class="text-2xl font-semibold">
            {{ event.name }}
          </div>
          <div class="flex justify-evenly text-lg">
            <div>
              {{ event.start_date.format('do MMMM Y') }}
            </div>
            <div class="pb-2 text-lg font-semibold text-brand-500">
              {{ event.type.name }}
            </div>
          </div>
        </div>
        <cgn-button v-if="buttonLink(event)" color-brand>
          <template v-if="event.can_enter">
            Enter
          </template>
          <template v-else>
            View More
          </template>
        </cgn-button>
      </cgn-link>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { $axios } from '~cognito/plugins/axios'
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
}
</script>

<script setup lang="ts">
defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})
const events = ref<EventEvent[]>([])

const buttonLink = (event) => {
  if (event.can_enter) {
    return `/event/${event.url}`
  }
  if (event.entry_form_link) {
    return event.entry_form_link
  }
  if (event.hirer_entry_form_link) {
    return event.hirer_entry_form_link
  }
  return ''
}

async function loadEvents() {
  const data = await $axios.graphql(gql`
  {
  eventEvents(first: 3, isFuture: true) {
    id
    name
    url
    can_enter
    entry_form_link
    image(image_aspect: "4x3", image_width: 600) {
      url
      width
      height
    }
    hirer_entry_form_link
    type {
      name
      photo(image_aspect: "4x3", image_width: 600) {
        url
        width
        height
      }
    }
    start_date
  }
  }
  `)
  events.value = new EventEvent().map(data.eventEvents)
}
onMounted(() => {
  loadEvents()
})
onServerPrefetch(async () => {
  await loadEvents()
})
</script>
