<template>
  <div class="mx-auto my-2 w-full max-w-2xl">
    <div v-if="has_payment">
      <cgn-alert-success v-if="payment_ok">
        Payment Successful
        Please check your email for your receipt
      </cgn-alert-success>
      <cgn-alert-danger v-else>
        Payment Failed
      </cgn-alert-danger>
    </div>
    <cgn-spinner v-else-if="loading" />
    <cgn-alert-danger v-else-if="!seriesDetails.eventSeries">
      Series not found
    </cgn-alert-danger>
    <div v-else>
      <div class="text-2xl">
        {{ seriesDetails.eventSeries.name }}
      </div>
      <div class="prose max-w-none" v-html="seriesDetails.eventSeries.content" />
      <form class="mx-auto max-w-6xl p-2" @submit.prevent="submitEntry">
        <cgn-form-dropdown v-model="seriesEntryForm.category_id" :options="seriesDetails.eventSeries.seriesCategories" label="Series Category" required />
        <cgn-form-dropdown
          v-model="seriesEntryForm.vehicle_id"
          label="Vehicle"
          prompt="New Vehicle"
          :options="vehicles.map(e => { return { id: e.id, name: `${e.make} ${e.model} ${e.colour} ${e.registration}` } })"
          class="w-full"
          required
        />
        <cgn-vehicle-edit v-if="!seriesEntryForm.vehicle_id" v-model="seriesEntryForm.vehicle_id" v-model:vehicles="vehicles" no-scroll :require-registration="false" />
        <cgn-form-input v-model="seriesEntryForm.series_number" label="Preferred Series Race Number" type="number" :min-amount="1" :max-amount="999" />
        <cgn-form-dropdown v-model="has_transponder" label="Transponder" :options="has_transponder_options" required />
        <cgn-form-input v-if="has_transponder" v-model="seriesEntryForm.transponder_number" label="Series Transponder Number" required />
        <cgn-form-dropdown v-model="seriesEntryForm.t_shirt_size" :options="t_shirt_sizes" label="T Shirt Size" required />
        <cgn-form-address-selector
          v-model="seriesEntryForm.address_id"
          here-api-key="GOBLSSIkkrgjhMahFmXPramj-95rVXZYpj-0pj7DsFU"
        />
        <cgn-form-input-phone v-if="!useUserStore().user.mobile_phone" v-model="phone" label="Phone" required />
        <cgn-form-input v-if="!useUserStore().user.date_of_birth" v-model="dob" label="Date of Birth" type="date" required />
        <cgn-alert-danger v-if="errorMessage">
          {{ errorMessage }}
        </cgn-alert-danger>
        <cgn-button color-brand fullwidth class="font-bold">
          Pay ${{ seriesDetails.eventSeries.entry_fee.toFixed(2) }} Now
        </cgn-button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { gql } from 'graphql-request'
import { CognitoPayment } from '~cognito/models/Cognito/Payment'
import { EventVehicle } from '~cognito/models/Event/Vehicle'
import { $axios, requireLoggedIn } from '~cognito/plugins/axios'

class Templatevars {
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
  series: {
    type: String,
    required: true,
  },
})

const loading = ref(false)
const errorMessage = ref('')
const has_payment = ref(false)
const payment_ok = ref(false)
const phone = ref('')
const dob = ref('')

const seriesDetails = ref({})
const vehicles = ref<EventVehicle[]>([])

const t_shirt_sizes = ref([
  'S', 'M', 'L', 'XL', '2XL', '3XL',
])
const has_transponder = ref(null)
const has_transponder_options = ref([
  { id: 0, name: 'No - I need to hire a transponder' },
  { id: 1, name: 'Yes - I have a transponder I will use' },
])
const seriesEntryForm = ref({
  series_id: 0,
  category_id: 0,
  vehicle_id: 0,
  address_id: 0,
  t_shirt_size: '',
  series_number: '',
  transponder_number: '',
  url: '',
})

const submitEntry = async () => {
  if (phone.value) {
    // Save phone
    $axios
      .post('/api/v1/cognito/user/update', {
        id: useUserStore().user.id,
        mobile_phone: phone.value,
      })
      .then(() => {
        useUserStore().user.mobile_phone = phone.value
      })
  }
  if (dob.value) {
    // Save date of birth
    $axios
      .post('/api/v1/cognito/user/update', {
        id: useUserStore().user.id,
        date_of_birth: dob.value,
      })
      .then(() => {
        useUserStore().user.date_of_birth = dob.value
      })
  }
  errorMessage.value = ''
  seriesEntryForm.value.url = location.href
  const result = (await $axios.post('/api/v1/event/series/submitEntry', seriesEntryForm.value)).data

  errorMessage.value = result.message
  if (result.success) {
    window.location = result.redirect
  }
}

onMounted(async () => {
  requireLoggedIn()
  loading.value = true
  new CognitoPayment().checkSuccessful()
    .then((data) => {
      has_payment.value = data.has_payment
      payment_ok.value = data.success
    })

  seriesDetails.value = await $axios.graphql(gql`query seriesQuery($groupUrl: String!) {
  eventSeries(url: $groupUrl) {
    id
    name
    content
    entry_fee
    seriesCategories {
      id
      name
      code
    }
  }
}`, {
    groupUrl: props.series,
  })

  seriesEntryForm.value.series_id = seriesDetails.value.eventSeries.id

  vehicles.value = (await new EventVehicle().find_many({
    entrant_id: useUserStore().user.id,
  })).mapped

  if (vehicles.value[0]) {
    seriesEntryForm.value.vehicle_id = vehicles.value[0].id
  }

  loading.value = false
})
</script>
