<template>
  <div class="bg-brand-500">
    <div class="text-2xl font-bold text-white text-center py-3">
      {{ templatevar.heading }}
    </div>
    <div class="relative mb-8">
      <cgn-lazy-image :image-id="templatevar.image" image-aspect="16x5" image-width="1920" class="hidden sm:block w-full" />
      <cgn-lazy-image :image-id="templatevar.mobile_image" image-aspect="4x3" image-width="1920" class="sm:hidden w-full" />
      <div class="absolute bottom-0 inset-x-0 m-4 flex justify-between">
        <div>
          <cgn-button color-brand class="uppercase font-bold text-2xl" :url="`/event/${event.url}`">
            <i-fa6-solid:arrow-up-right-from-square class="inline sm:w-7 sm:h-7 mr-1 sm:mr-2" />
            Get tickets
          </cgn-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { EventEvent } from '~cognito/models/Event/Event'

class Templatevars {
  heading?: string
  image?: string
  mobile_image?: string
  event?: number
}
</script>

<script setup lang="ts">
const props = defineProps({
  templatevar: {
    type: Templatevars,
    required: true,
  },
})

const event = ref<EventEvent>(new EventEvent())

async function loadEvent() {
  const data = await new EventEvent().find_one({
    id: props.templatevar.event,
  })
  event.value = data
}
onMounted(() => {
  loadEvent()
})
onServerPrefetch(async () => {
  await loadEvent()
})
</script>
